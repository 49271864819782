import React from 'react';

import {graphql} from "gatsby";
import Layout from '../components/layout';
import SEO from '../components/seo';
// import ImagePoster from "../components/image_poster"
import SideBar from '../components/sidebar';
import PostsList from '../components/post_list';

const PostsPage = (props) => (
	<Layout>
		{/* {console.log(props.data)} */}

		<SEO description="Blog posts page." title="Posts" />
		<SideBar path={props.path} />
		<div className="content posts-container">
			<h1 className="page-title">Recent Posts</h1>
			<PostsList data={props.data} />
		</div>
	</Layout>
);

export default PostsPage;

export const pageQuery = graphql`
	{
		allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
			edges {
				node {
					id
					frontmatter {
						title
						date(formatString: "DD MMM, YYYY")
						tags
					}
					excerpt
					fields {
						slug
					}
				}
			}
		}
	}
`;
