import React from 'react';
// import { Link } from 'gatsby';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

class Pagination extends React.Component {
	constructor(props) {
		super(props);
		this.itemsPerPage = props.itemsPerPage;
		this.changePage = props.changePage;
		this.maxVisPages = props.maxPages;
		this.state = {
			totalPages: props.totalPages,
			currentPage: props.startPage,
			pageNumbers: Array.from({ length: props.totalPages }, (_, i) => i + 1)
		};
	}

	handlePageChange = (page) => {
		page = page > this.state.totalPages ? this.state.totalPages : page;
		page = page < 1 ? 1 : page;

		this.setState({ currentPage: page });
		// console.log(this.state.currentPage);
		this.changePage(page);
	};

	// renderPagination;

	render() {
		// console.log(this.state.totalPages)
		if (this.state.totalPages === 1 || !this.state.totalPages) {
			
			return null;
		}

		const visPageNumbers = selectRelevantPageLinks(this.state.currentPage, this.state.totalPages);

		return (
			<ul className="pagination">
				<button
					onClick={this.handlePageChange.bind(this, this.state.currentPage - 1)}
				>
					<FaArrowLeft className={this.state.currentPage === 1 ? 'disabled' : null} />{' '}
				</button>
				{visPageNumbers.map((pageNumber) => {
					if (isNaN(pageNumber)) {
						return (
							// <span  onClick={this.showMorePages.bind(this, pageNumber)}>
							<li key={pageNumber} className="dots">
								...
							</li>
							// </span>
						);
					}
					return (
						<button key={pageNumber} onClick={this.handlePageChange.bind(this, pageNumber)}>
							<li className={this.state.currentPage === pageNumber ? 'currentPage' : null}>
								{pageNumber}
							</li>
						</button>
					);
				})}

				<button onClick={this.handlePageChange.bind(this, this.state.currentPage + 1)}>
					<FaArrowRight className={this.state.currentPage === this.state.totalPages ? 'disabled' : null} />
				</button>
			</ul>
		);
	}
}

function selectRelevantPageLinks(currentPage, countPages) {
	var visiblePageNumbers = [];
	if (countPages <= 5) {
		/* If there are not too much, show everything. */
		for (let i = 1; i <= countPages; i++) {
			visiblePageNumbers.push(i);
		}
	} else {
		/* Always show beginning, end, current, and around current. */
		if (currentPage <= 5) {
			/* If beginning and current are not too far, we don't want to "dot dot" between them. */
			for (let i = 1; i < currentPage; i++) {
				visiblePageNumbers.push(i);
			}
		} else {
			visiblePageNumbers.push(1);
			visiblePageNumbers.push('dots-left-half');
			visiblePageNumbers.push(currentPage - 2);
			visiblePageNumbers.push(currentPage - 1);
		}
		visiblePageNumbers.push(currentPage);
		if (currentPage >= countPages - 4) {
			/* If current and end are not too far, we don't want to "dot dot" between them. */
			for (let i = currentPage + 1; i < countPages; i++) {
				visiblePageNumbers.push(i);
			}
		} else {
			visiblePageNumbers.push(currentPage + 1);
			visiblePageNumbers.push(currentPage + 2);
			visiblePageNumbers.push('dots-right-half');
		}
		if (currentPage !== countPages) {
			visiblePageNumbers.push(countPages);
		}
	}
	return visiblePageNumbers;
}

export default Pagination;
