import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Pagination from './paginate';

const PostsList = (props) => {
	const { allMarkdownRemark } = props.data;

	const itemsPerPage = 9;
	const maxPages = 10;

	const [ searchedPosts, setSearchedPosts ] = useState(allMarkdownRemark.edges);
	const [ totalPages, setTotalPages ] = useState(Math.ceil(searchedPosts.length / itemsPerPage));
	const [ currentPage, setCurrentPage ] = useState(1);

	const paginationRef = React.createRef();

	// const [ searchedText, setSearchedText ] = useState('');
	const [ typingTimeout, setTypingTimeout ] = useState(0);
	// const [ isTyping, setIsTyping ] = useState(false);

	

	useEffect(()=> {
		// console.log(totalPages)
		paginationRef.current.setState({
			currentPage: currentPage,
			totalPages: totalPages,
			pageNumbers: Array.from({ length: totalPages }, (_, i) => i + 1)
		});
	})


	const handleChange = (event) => {
		// console.log(event)
		if (typingTimeout) {
			clearTimeout(typingTimeout);
		}
		const val = event.target.value;
		setTypingTimeout(setTimeout(() => {
			// console.log(val)
			doSearch(val);
		  }, 500))
	 
	}

	const renderPostCard = (post) => {
		return (
			<Link to={post.node.fields.slug} key={post.node.id} className="list-post">
				<h3 className="">{post.node.frontmatter.title}</h3>
				<p className="text-xs">{post.node.frontmatter.date}</p>
				<p>{post.node.excerpt}</p>
				<hr className="bg-grey h-px" />
				{post.node.frontmatter.tags.map((tag, index) => (
					<span key={index} className="inline-block text-sm mr-2 italic">
						{tag}
					</span>
				))}
			</Link>
		);
	};

	const doSearch = (val) => {
		// const search_val = event.target.value.toLowerCase();
		const search_val = val.toLowerCase();


		const searched_posts = allMarkdownRemark.edges.filter((item) => {
			let tags = '';
			const tags_array = item.node.frontmatter.tags;
			for (var i = 0, len = tags_array.length; i < len; i++) {
				tags += tags_array[i];
			}

			return (
				item.node.frontmatter.title.toLowerCase().indexOf(search_val) !== -1 ||
				item.node.frontmatter.date.toLowerCase().indexOf(search_val) !== -1 ||
				tags.indexOf(search_val) !== -1
			);
		});

		setSearchedPosts(searched_posts);
		const total = Math.ceil(searched_posts.length / itemsPerPage);
		setTotalPages(total);
		setCurrentPage(1);
	};

	const changePage = (page) => {
		setCurrentPage(page);
	};

	const getCurrentRange = (pageNumber) => {
		if (pageNumber === 1) {
			return searchedPosts.length < itemsPerPage ? [] : [ 0, itemsPerPage ];
		} else if (pageNumber > 1) {
			let startIndex = (pageNumber - 1) * itemsPerPage;
			return searchedPosts.length < startIndex + itemsPerPage
				? [ startIndex ]
				: [ startIndex, startIndex + itemsPerPage ];
		}
	};

	return (
		<section>
			{/* <h1 className="page-title">Recent Posts</h1> */}
			<input placeholder="Search posts by title, date or tags" onChange={handleChange.bind(this)} className="search-input" />
			<div className="flex flex-row flex-wrap justify-center">
				{searchedPosts.length ? null : 'No posts to show'}

				{searchedPosts.slice(...getCurrentRange(currentPage)).map((post) => {
					return renderPostCard(post);
				})}
			</div>

			<Pagination
				ref={paginationRef}
				totalPages={totalPages}
				startPage={currentPage}
				itemsPerPage={itemsPerPage}
				changePage={changePage}
				maxPages={maxPages}
			/>
		</section>
	);
};

export default PostsList;
